const BACKEND_URL = 'http://admin.leoyiblog.cn/';

// const BACKEND_PORT = '3000';

export const NET_CONFIG: {
    // BACKEND_PORT: string,
    BACKEND_URL:string
} = {
    // BACKEND_PORT,
    BACKEND_URL
}  