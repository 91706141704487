
import { Options, Vue } from "vue-class-component";
import { loginByPwd } from "../api/user.api";
import { mapState } from 'vuex'
@Options({
  computed:{
    ...mapState({
      'err_msg':'err_msg'
    })
  }
})
export default class Login extends Vue {
  username = "";
  password = "";
  async login(): Promise<void> {
    const res = await loginByPwd({
      username: this.username,
      password: this.password,
    });
    if (res&&res.data.res == true) {
      // 登陆成功，导航到home页面申请ticket_token
      this.$router.replace({
        path:'/',
        query:{
          redirect:this.$route.query.redirect as string
        }
      })
    }
  }
}
